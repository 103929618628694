import { ref, watch } from '@vue/composition-api'

export default function useProviderHandler(props, emit) {
  // ------------------------------------------------
  // ProviderLocal
  // ------------------------------------------------
  const providerLocal = ref(JSON.parse(JSON.stringify(props.provider.value)))
  const resetProviderLocal = () => {
    providerLocal.value = JSON.parse(JSON.stringify(props.provider.value))
  }
  watch(props.provider, () => {
    resetProviderLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const providerData = JSON.parse(JSON.stringify(providerLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event

    if (props.provider.value.id) emit('update-provider', providerData.value)
    else emit('add-provider', providerData.value)

    // Close sidebar
    emit('update:is-provider-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    providerLocal,
    resetProviderLocal,

    // UI
    onSubmit,
  }
}
