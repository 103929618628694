<template>
  <section id="dashboard-analytics">
    <b-container>
      <b-row>
        <b-col cols="8" />
        <b-col
          cols="4"
          class="d-flex justify-content-end mb-2"
        >
          <b-button-group>
            <b-dropdown
              right
              variant="outline-primary"
              text="Add"
            >
              <b-dropdown-item @click="isProviderActivityHandlerSidebarActive = true">
                Add Provider
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              right
              variant="outline-primary"
              text="Settings"
            >
              <b-dropdown-item>Coming soon</b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-card
            border-variant="success"
          >
            <b-tabs
              pills
              nav-class="mb-3"
            >
              <b-tab
                title="Provider Details"
                active
              >
                <detail-provider-handler
                  :provider-list="activeListProvider"
                  :field-description="tableFields"
                  @click-row="handleProviderClick"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <!-- Provider Handler -->
      <provider-handler-sidebar
        v-model="isProviderActivityHandlerSidebarActive"
        :provider="provider"
        :clear-provider-data="clearProviderData"
        @remove-provider="deleteProvider"
        @add-provider="createProvider"
        @update-provider="editProvider"
      />
    </b-container>
  </section>
</template>

<script>
import {
  BButtonGroup, BCard, BCol, BContainer, BDropdown, BDropdownItem, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { toRefs } from '@vue/composition-api'
import store from '@/store'
import {
  createProviderApi, deleteProviderApi, editProviderApi, getAllProviderAPI, getProviderByIdAPI,
} from '@/libs/api'
import DetailProviderHandler from '@/views/ipam/provider/DetailProviderHandler'
import ProviderHandlerSidebar from '@/views/ipam/provider/ProviderHandlerSidebar'
import useDashboardProviderBoardHandler from '@/views/ipam/provider/useDashboardProviderBoardHandler'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BCard,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    DetailProviderHandler,
    ProviderHandlerSidebar,
  },
  data() {
    return {
      data: {},
      isLoadIp: false,
      isReloadTree: false,
      activeListProvider: [],
    }
  },
  beforeMount() {
    this.getAllProvider()
  },
  methods: {
    kFormatter,
    errorShow(error) {
      console.log('error', error)
      this.showToast('danger', error.toString(), 'Error')
    },
    createProvider(provider) {
      if (provider === undefined || provider === null) {
        return
      }
      createProviderApi(provider).then(response => {
        if (response.status === 201) {
          store.commit('ipamProvider/PUSH_PROVIDER', response.data)
        } else {
          this.showToast('danger', `${response.data.code}: ${response.data.message}`, 'Error')
        }
      }).catch(error => {
        this.errorShow(error)
      })
    },
    editProvider(provider) {
      if (provider === undefined || provider === null) {
        return
      }
      editProviderApi(provider).then(response => {
        if (response.status === 201) {
          store.commit('ipamProvider/PUT_PROVIDER_LIST', response.data)
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      })
    },
    deleteProvider(provider) {
      if (provider === undefined || provider === null) {
        return
      }
      deleteProviderApi(provider).then(response => {
        if (response.status === 200) {
          store.commit('ipamProvider/DELETE_PROVIDER_BY_ID', response.data.id)
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      })
    },
    getProviderById(id) {
      if (id !== undefined && id.length() > 0) {
        getProviderByIdAPI(id)
          .then(response => {
            // eslint-disable-next-line no-underscore-dangle
            store.commit('ipamProvider/PUT_PROVIDER_LIST', response.data)
          })
      }
    },
    getAllProvider() {
      getAllProviderAPI()
        .then(response => {
          if (response.status === 200) {
            // eslint-disable-next-line no-underscore-dangle
            if (response.data._embedded !== undefined) {
              // eslint-disable-next-line no-underscore-dangle
              const { providerBaseDtoes } = response.data._embedded
              store.commit('ipamProvider/SET_PROVIDER_LIST', providerBaseDtoes)
            }
          }
        }).finally(() => {
          this.activeListProvider = store.getters['ipamProvider/getProviderAllList']
        })
    },
    showToast(variant, text, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
  },
  setup(props, { emit }) {
    const {
      // Provider Right Sidebar Action
      provider,
      clearProviderData,
      isProviderActivityHandlerSidebarActive,
      // Subnet Detail
      indexLastClickedRowSubD,
      handleProviderClick,
      tableFields,
    } = useDashboardProviderBoardHandler(toRefs(props), emit)

    return {
      provider,
      clearProviderData,
      isProviderActivityHandlerSidebarActive,
      handleProviderClick,
      // Subnet Detail Table
      tableFields,
      indexLastClickedRowSubD,
    }
  },
}
</script>
