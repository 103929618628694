<template>
  <div>
    <b-sidebar
      id="sidebar-provider-handler"
      sidebar-class="sidebar-lg"
      :visible="isProviderHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-provider-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="!providerLocal.id"
            class="mb-0"
          >
            Add Provider
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            Update Provider {{ providerLocal.name }}
          </h5>
          <div>
            <feather-icon
              v-show="providerLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-provider', providerLocal); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Provider Name -->
            <validation-provider
              #default="validationContext"
              name="Provider Name"
              rules="max:200|min:1"
            >
              <b-form-group
                label="Provider Name"
                label-for="provider-name"
              >
                <b-form-input
                  id="provider-comment"
                  v-model="providerLocal.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Provider Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Provider Comment -->
            <validation-provider
              #default="validationContext"
              name="Provider Comment"
              rules="max:230"
            >
              <b-form-group
                label="Provider Comment"
                label-for="provider-comment"
              >
                <b-form-input
                  id="provider-comment"
                  v-model="providerLocal.comment"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Provider Comment"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ providerLocal.id ? 'Update' : 'Add ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  email, length, required, url,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { integer, max, min } from '@/libs/custom-validations'
import useProviderHandler from '@/views/ipam/provider/useProviderHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isProviderHandlerSidebarActive',
    event: 'update:is-provider-handler-sidebar-active',
  },
  props: {
    isProviderHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    provider: {
      type: Object,
      required: true,
    },
    clearProviderData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      length,
      max,
      min,
      integer,
    }
  },
  setup(props, { emit }) {
    const {
      providerLocal,
      resetProviderLocal,

      // UI
      assigneeOptions,
      onSubmit,
    } = useProviderHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetProviderLocal(), props.clearProviderData)

    return {
      // Add New
      providerLocal,
      onSubmit,
      assigneeOptions,
      // usageTypes,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
