/* export default function useSubnetDetailHandler(props, emit) { */
import { ref } from '@vue/composition-api'

export default function useDashboardProviderBoardHandler() {
  const blankProvider = {
    id: null,
    name: null,
    comment: '',
  }
  const provider = ref(JSON.parse(JSON.stringify(blankProvider)))
  const clearProviderData = () => {
    provider.value = JSON.parse(JSON.stringify(blankProvider))
  }

  const isProviderActivityHandlerSidebarActive = ref(false)
  let indexLastClickedRowSubD = null
  const handleProviderClick = (item, index) => {
    indexLastClickedRowSubD = index
    provider.value = item
    isProviderActivityHandlerSidebarActive.value = true
  }

  const tableFields = [
    { key: 'id', label: 'Id' },
    { key: 'name', label: 'Name', sortable: true },
    { key: 'comment', label: 'Comment', sortable: true },
  ]

  return {
    // Right Sidebar Action
    blankProvider,
    provider,
    clearProviderData,
    isProviderActivityHandlerSidebarActive,
    handleProviderClick,
    // Subnet Detail
    indexLastClickedRowSubD,
    tableFields,
  }
}
